import React from 'react';
import "./Copyright.css";

export default function Copyright() {
  return (
    <div className="copyright">
      <p>Copyright © 2023 Jun Chai Photography</p>
    </div>
  )
}
