import React, { useState } from 'react'
import portrait from "../Images/image 2.png";
import engagement from "../Images/juncphotography/thumbs/2-min.jpeg";
import { HashLink as Link } from 'react-router-hash-link';

export default function Work() {
    const[myPortrait, setMyPortrait]= useState(false);
    const[myEngagement, setMyEngagement]= useState(false);
    const[defaultPortrait, setDefaultPortrait]= useState(true);
    const[defaultEngagement, setDefaultEngagement]= useState(true);


  return (
    <div>
      <div className="portfolio"
        onMouseLeave={() => {
            setMyPortrait(false);
            setMyEngagement(false);
            setDefaultEngagement(true);
            setDefaultPortrait(true);
        }}>
      
      <h2>Portfolio</h2>
      <div className="portfolio-sections" >

        {defaultPortrait && (<div className="portrait-lifestyle"
        onMouseOver={() => setMyPortrait(true)}
        onMouseEnter={() => setDefaultEngagement(false)}>
          <Link to="/Lifestyle-and-Portrait"> 
          <img src={portrait} alt="portrait" />
          </Link>

          
          
        </div>)}

        {myEngagement && (<div className="engagement-menu">
        <h3>Engagement</h3>
        <p>The moment you declare your love is sacred and eternal. With my experience, we'll be able to capture the love you and your partner feel to hold as a dear memory forever.</p>

            </div>)}

        {myPortrait && (
            <div className="portrait-reveal"
            onMouseLeave={() => setMyPortrait(false)}>
                
            </div>
        )}  
        
            {defaultEngagement && (<Link to="/Engagement"><div className="Engagement-work"
            onMouseOver={() => setMyEngagement(true)}
            onMouseEnter={() => setDefaultPortrait(false)}
            >
                
                <img src={engagement} alt="engagement" />
                
          
            </div></Link>)}

            {myPortrait && (<div className="portrait-menu">
                <h3>Lifestyle & Portraits</h3>
                <p>From showing off your individuality to showing off how marketable you are in business, my professional Portrait and Lifestyle photo shoots will take you to the next level and really make you stand out!</p>
                
            </div>)}

            {myEngagement && (
                <div className="engagement-reveal"
                onMouseLeave={() => {setMyEngagement(false); setDefaultPortrait(true)}}>
                    
                </div>
            )}  
      </div>

            {defaultPortrait && defaultEngagement && (<div className="portfolio-backgrounds">
                <div className="portrait-background"></div>
                <div className="Engagement-background"></div>
            </div>)}
        </div>
    </div>
  )
}
