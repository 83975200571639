import { Routes, Route } from "react-router-dom"; 
import './App.css';
import Copyright from './Components/Copyright.jsx';
import HomePage from './Pages/HomePage.jsx';
import ServicesAndPricing from './Pages/ServicesAndPricing.jsx';
import Contact from './Pages/Contact.jsx';
import Lifestyle from './Pages/Lifestyle.jsx';
import Engagement from './Pages/Engagement.jsx';

function App() {



  return (
    
    <div className="App">
      <Routes>
        <Route path='/' element={<HomePage/>} />
        <Route path='/services' element={<ServicesAndPricing />}  />
        <Route path='/contactme' element={<Contact />} />
        <Route path='/Lifestyle-and-Portrait' element={<Lifestyle />} />
        <Route path='/Engagement' element={<Engagement />} />
      </Routes>  
      <Copyright/>
    </div>  

  );
}

export default App;
