import React, {useState} from 'react';
import "./NavEngagement.css";
import { HashLink as Link } from 'react-router-hash-link';
import { NavLink } from 'react-router-dom';


function Nav() {

  const[dropDown, setDropDown] = useState(false);
  return (
    <div className="nav3">
      <div className="main-frame3">
        <div className="gohome3">
          <Link to="/#top">
            <div className="jun3">
              jun
            </div>
            <div className="between3">
              .
            </div>
            <div className="jun3">
              c
            </div>
            
          </Link>
        </div>
        
        <div className="links3">
          
          <div className="dropdown3">
              <div className="wrap3">
                Work
                <div className="rectangle3">
                </div>
              </div>
            <div className="dropdown-content3">
              <NavLink to="/Lifestyle-and-Portrait">
                <div className="dropdown-button3">
                  Lifestyle & Portraits
                </div>
              </NavLink>
              <NavLink to="/Engagement">
                <div className="dropdown-button3">
                  Engagement
                </div>
              </NavLink>
            </div>
          </div>
          
          
          <NavLink to="/services">
            Services & Pricing
          </NavLink>
          
          <Link to="/#aboutme">
            About
          </Link>

          <NavLink to="/contactme">
            Contact
          </NavLink>
        </div>

      </div>

      <div className="mobile-dropdown3">
            {dropDown && (<div className="dropdown-content3">
              <NavLink to="/Lifestyle-and-Portrait">
                <div className="dropdown-button3">
                  Lifestyle & Portraits
                </div>
              </NavLink>
              <NavLink to="/Engagement">
                <div className="dropdown-button3">
                  Engagement
                </div>
              </NavLink>
              <NavLink to="/services">
                <div className="dropdown-button3">
                  Services & Pricing
                </div>
              </NavLink>

              <NavLink to="/contactme">
                <div className="dropdown-button3">
                  Contact
                </div>  
              </NavLink>
            </div>)}


            <div className="dropdown3">
              <div className="mobile-click3" 
              onClick={() => setDropDown(dropDown ? false : true)}>
                ニ
              </div>
            </div>

         </div>   
        
       

 
    </div>
  )
}

export default Nav;
