import React, { useRef, useEffect, useState } from 'react'
import "./Contact.css";
import icon1 from "../Images/icon1.png";
import icon2 from "../Images/icon2.png";
import icon3 from "../Images/icon3.png";
import contactSplash from "../Images/contactme.png";
import Nav from '../Components/Nav.jsx';
import emailjs from '@emailjs/browser';
import DocumentMeta from 'react-document-meta';
import useScript from "../Components/useScript";

export default function Contact() {

  useScript('https://www.google.com/recaptcha/api.js');

  const [message, setMessage] = useState("");
    const [message2, setMessage2] = useState("");
    const [message3, setMessage3] = useState("");
    

    const handleChange = event => {
        setMessage(event.target.value);
      };
    const handleChange2 = event => {
        setMessage2(event.target.value);
      };
    const handleChange3 = event => {
        setMessage3(event.target.value);
      };


  useEffect(() => {
    document.title = "Let's Connect: Reach out to me.";
  }, []);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_zdax107', 'template_y5s20mb', form.current, 'wopzxqh0ARpnWLOMt')
      .then((result) => {
          console.log(result.text);
          alert("Email sent! You'll hear back from me soon!");
          document.location.reload();
      }, (error) => {
          console.log(error.text);
          alert("NOT SENT! Make sure all fields are valid and the reCAPTCHA challenge is completed, and try again.")
          
      });
  };

  const meta = {
    title: "Let's Connect: Reach out to me.",
    description: "Get in touch through my contact page. I am dedicated to delivering exceptional results and personalized attention to each of my clients. Contact me today to learn more about my services and to schedule your session.",
    canonical: 'https://juncphotography.com/contactme',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'Engagement photography, Portrait photography, Lifestyle photography, Love, Relationships, Candid, Intimate, Personal, Memories, Emotions, Poses, Natural Lighting, Creative, Storytelling, Expression, Joy, Elegance, Style, Personality, Fun'
      }
    }
  };


  return (
    <div className="contact">
      <DocumentMeta {...meta} />
        <Nav />
        <div className="green-square"></div>
        <div className="getintouch2">
            <h2>Get in Touch</h2>
            <form ref={form} onSubmit={sendEmail}>
              <div className="name-email">
                <div className="textfield">
                  <label for="name">Full Name</label>
                  <input type="text" id="name" name="from_name" value={message} onChange={handleChange} required  />
                </div>
                <div className="textfield">
                  <label for="email">Email Address</label>
                  <input type="email" id="email" name="from_email" placeholder="user@example.com" value={message2} onChange={handleChange2} required  />
                </div>
              </div>
              <div className="selection">
                <label for="interest">I am interested in...</label>
                <select id="interest" name="session_type"  >
                  <option value="Portrait Session">Portrait Session</option>
                  <option value="Lifestyle Session">Lifestyle Session</option>
                  <option value="Engagement Session">Engagement Session</option>
                </select>
              </div>
              <div className="message">
                <label for="message">Message</label>
                <textarea className="message-input" name="message" value={message3} onChange={handleChange3} required ></textarea>
              </div>
              
              <div className="submit">
                <div class="g-recaptcha" data-sitekey="6LfX2_YlAAAAAIccRCQrHvQMFb9eaazs_9VJp3N5"></div>
                <br />
                <button disabled={message==="" || message2==="" || message2.length<9 || message3==="" || message3.length<20} type="submit">Send Message</button>
              </div>
            </form>  
            <div className="contact-methods">
                    <div className="contact-item">
                      <img src={icon1} alt="email" />  
                      <p>juncphotography.ca@gmail.com</p>
                    </div>
                    <div className="contact-item">
                      <img src={icon2} alt="phone" />    
                      <p>(714)-234-4110</p>
                    </div>
                    <div className="contact-item"> 
                      <img src={icon3} alt="instagram" />  
                      <p>junc_photography</p>
                    </div>
            </div>
        </div>
        

        <div className="contact-splash">
            <img src={contactSplash} alt="contact" />
        </div>

      
    </div>
  )
}
