import React from 'react'

import "./BackToTop.css";
import arrow from "../Images/arrow.png";
export default function BackToTop() {
  return (
    <div className="totopcontroller">
      <div className="top">
          <div className="content">
            <h4>Back to top</h4>
            <img src={arrow} alt="arrow" />
          </div>
      </div>
    </div>
  )
}
