import React, { useRef, useEffect, useState } from 'react'
import './Engagement.css'
import Nav from '../Components/NavEngagement.jsx';
import engagementSplash from '../Images/juncphotography/thumbs/engagementsplash-min.jpeg';
import engagementIntro from '../Images/juncphotography/thumbs/engagementintro-min.jpeg';
import image1 from '../Images/juncphotography/thumbs/1-min.jpeg';
import image2 from '../Images/juncphotography/thumbs/2-min.jpeg';
import image3 from '../Images/juncphotography/thumbs/3-min.jpeg';
import image4 from '../Images/juncphotography/thumbs/4-min.jpeg';
import image5 from '../Images/juncphotography/thumbs/5-min.jpeg';
import image6 from '../Images/juncphotography/thumbs/6-min.jpeg';
import emailjs from '@emailjs/browser';
import DocumentMeta from 'react-document-meta';
import useScript from "../Components/useScript";

export default function Engagement() {

  useScript('https://www.google.com/recaptcha/api.js');

  const [message, setMessage] = useState("");
    const [message2, setMessage2] = useState("");
    const [message3, setMessage3] = useState("");
    

    const handleChange = event => {
        setMessage(event.target.value);
      };
    const handleChange2 = event => {
        setMessage2(event.target.value);
      };
    const handleChange3 = event => {
        setMessage3(event.target.value);
      };


  useEffect(() => {
    document.title = 'Love in Focus: Engagement Photography by Jun Chai';
  }, []);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_zdax107', 'template_y5s20mb', form.current, 'wopzxqh0ARpnWLOMt')
    .then((result) => {
        console.log(result.text);
        alert("Email sent! You'll hear back from me soon!");
        document.location.reload();
    }, (error) => {
        console.log(error.text);
        alert("NOT SENT! Make sure all fields are valid and the reCAPTCHA challenge is completed, and try again.")
        
    });
};

  const meta = {
    title: 'Love in Focus: Engagement Photography by Jun Chai',
    description: "Capture the essence of your love story with my engagement photography services.  I specialize in creating stunning and timeless images that reflect the joy and intimacy of your relationship. Browse my portfolio and let me help you capture your special moments.",
    canonical: 'https://juncphotography.com/Engagement',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'Engagement photography, Portrait photography, Lifestyle photography, Love, Relationships, Candid, Intimate, Personal, Memories, Emotions, Poses, Natural Lighting, Creative, Storytelling, Expression, Joy, Elegance, Style, Personality, Fun'
      }
    }
  };


  return (
    <div className="Engagement-page">
      <DocumentMeta {...meta} />
      <Nav />
      <div className="Engagement-landing" >
        <div className="purple-square1"></div>  
        <div className="engagement-splash" >
            <img src={engagementSplash} alt="splash" />
        </div>  
        <div className="engagement-intro">
            <div className="engagement-text">
                <h3>JUN CHAI</h3>
                <h2>Engagement Photography</h2>
                <p>I understand the importance of capturing this special time in your relationship, and 
my goal is to create stunning images that reflect your love and joy. I work closely 
with each couple to create a personalized experience that is tailored to your unique 
style and preferences. Whether you're looking for a romantic, playful, or dramatic 
shoot, I'll work with you to bring your vision to life. So, let's celebrate your love story
and create beautiful images that you'll treasure for a lifetime.</p>
                <div className="engagement-buttons">
                    <form action="/contactme">
                        <button type="submit">Contact Me</button>
                    </form>
                    <div className="engagement-button2">
                        <form action='/services'>
                            <button type="submit">View Pricing</button>
                        </form>
                    </div>   
                </div>
            </div>


            <div className="engagement-splash-mobile" >
              <img src={engagementSplash} alt="splash" />
              <div className="mobile-button-side">
                <div className="engagement-buttons-mobile">
                      <form action="/contactme">
                          <button type="submit">Contact Me</button>
                      </form>
                      <div className="engagement-button2">
                          <form action='/services'>
                              <button type="submit">View Pricing</button>
                          </form>
                      </div>   
                </div>
                <div className="engagement-mobile-image">
                  <img src={engagementIntro} alt="intro" />
                </div>
              </div>  
            </div>  
            <div className="theintro">
              <img src={engagementIntro} alt="intro" />
            </div>
        </div>
     </div>  
     <div className="engagement-gallery">
        <div className="engagement-gallery-item">
            <img src={image1} alt="en1" />
        </div>
        <div className="engagement-gallery-item">
            <img src={image2} alt="en2" />
        </div>
        <div className="engagement-gallery-item">
            <img src={image3} alt="en3" />
        </div>
        <div className="engagement-gallery-item">
            <img src={image4} alt="en4" />
        </div>
        <div className="engagement-gallery-item">
            <img src={image5} alt="en5" />
        </div>
        <div className="engagement-gallery-item">
            <img src={image6} alt="en6" />
        </div>
     </div>


      
        <div className="leaveamessage">
            <h2>Leave a message</h2>
            <form ref={form} onSubmit={sendEmail}>
              <div className="name-email">
                <div className="textfield">
                  <label for="name">Full Name</label>
                  <input type="text" id="name" name="from_name" value={message} onChange={handleChange} />
                </div>
                <div className="textfield">
                  <label for="email">Email Address</label>
                  <input type="email" id="email" name="from_email" placeholder="user@example.com" value={message2} onChange={handleChange2} required />
                </div>
              </div>
              <div className="selection">
                <label for="interest">I am interested in...</label>
                <select id="interest" name="session_type">
                  <option value="Portrait Session">Portrait Session</option>
                  <option value="Lifestyle Session">Lifestyle Session</option>
                  <option value="Engagement Session">Engagement Session</option>
                </select>
              </div>
              <div className="message">
                <label for="message">Message</label>
                <textarea className="message-input" name="message" value={message3} onChange={handleChange3} required></textarea>
              </div>
              <div className="submit">
                <div class="g-recaptcha" data-sitekey="6LfX2_YlAAAAAIccRCQrHvQMFb9eaazs_9VJp3N5"></div>
                <br />
                <button disabled={message==="" || message2==="" || message2.length<9 || message3==="" || message3.length<20} type="submit">Send Message</button>
              </div>
            </form>  
          </div>
          

     <div className="purple-square2"></div>     

    </div>
  )
}
