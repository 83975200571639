import React, {useState} from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { NavLink } from 'react-router-dom';
import './NavLifestyle.css';


function Nav() {
  const[dropDown, setDropDown] = useState(false);

  return (
    <div className="nav2">
      <div className="main-frame">
        <div className="gohome">
          <Link to="/#top">
            <div className="jun">
              jun
            </div>
            <div className="between">
              .
            </div>
            <div className="jun">
              c
            </div>
            
          </Link>
        </div>
        
        <div className="links">
          
          <div className="dropdown">
              <div className="wrap">
                Work
                <div className="rectangle">
                </div>
              </div>
            <div className="dropdown-content">
              <NavLink to="/Lifestyle-and-Portrait">
                <div className="dropdown-button">
                  Lifestyle & Portraits
                </div>
              </NavLink>
              <NavLink to="/Engagement">
                <div className="dropdown-button">
                  Engagement
                </div>
              </NavLink>
            </div>
          </div>
          
          
          <NavLink to="/services">
            Services & Pricing
          </NavLink>
          
          <Link to="/#aboutme">
            About
          </Link>

          <NavLink to="/contactme">
            Contact
          </NavLink>
        </div>

        

        </div>    
        <div className="mobile-dropdown2">
            {dropDown && (<div className="dropdown-content">
              <NavLink to="/Lifestyle-and-Portrait">
                <div className="dropdown-button">
                  Lifestyle & Portraits
                </div>
              </NavLink>
              <NavLink to="/Engagement">
                <div className="dropdown-button">
                  Engagement
                </div>
              </NavLink>
              <NavLink to="/services">
                <div className="dropdown-button">
                  Services & Pricing
                </div>
              </NavLink>

              <NavLink to="/contactme">
                <div className="dropdown-button">
                  Contact
                </div>  
              </NavLink>
            </div>)}


            <div className="dropdown">
              <div className="mobile-click2" 
              onClick={() => setDropDown(dropDown ? false : true)}>
                ニ
              </div>
            </div>
        </div>
        
       

 
    </div>
  )
}

export default Nav;