
import Bert1 from "../Images/juncphotography/bert/DSC06699-Edit.jpg";
import Bert2 from "../Images/juncphotography/bert/DSC08687-Edit-Edit.jpg";
import Bert3 from "../Images/juncphotography/bert/DSC08698-Edit.jpg";
import Bert4 from "../Images/juncphotography/bert/DSC08764-Edit.jpg";
import Bert5 from "../Images/juncphotography/bert/DSC08819-Edit.jpg";
import Bert6 from "../Images/BertMain.jpeg";
import Louis1 from "../Images/juncphotography/louis/DSC07098-2-Edit-2.jpg";
import Louis2 from "../Images/juncphotography/louis/_DSC6503-Edit-Edit.jpg";
import Louis3 from "../Images/juncphotography/louis/_DSC6483-Edit-Edit.jpg";
import Louis4 from "../Images/juncphotography/louis/_DSC6461-Edit-2-Edit.jpg";
import Miguel1 from "../Images/juncphotography/Miguel - deliver/Deliver/_DSC5124-Edit-Edit.jpg";
import Miguel2 from "../Images/juncphotography/Miguel - deliver/Deliver/_DSC4923-Edit.jpg";
import Miguel3 from "../Images/juncphotography/Miguel - deliver/Deliver/_DSC5001.jpg";
import Miguel4 from "../Images/juncphotography/Miguel - deliver/Deliver/_DSC4910-Edit.jpg";
import Chelsea1 from "../Images/juncphotography/Chelsea/_DSC4305-Edit.jpg";
import Chelsea2 from "../Images/juncphotography/Chelsea/_DSC4308-Edit-Edit-Edit.png";
import Chelsea3 from "../Images/juncphotography/Chelsea/_DSC4321-Edit-Edit.jpg";
import Chelsea4 from "../Images/juncphotography/Chelsea/_DSC4334.png";
import Chelsea5 from "../Images/juncphotography/Chelsea/_DSC4241-Edit.jpg";
import Kevin1 from "../Images/juncphotography/kevin/_dsc6389-edit.jpg"
import Kevin2 from "../Images/juncphotography/kevin/_DSC0486-Edit-Edit-2.jpg"
import Kevin3 from "../Images/juncphotography/kevin/_DSC6393-Edit.jpg"
import Kevin4 from "../Images/juncphotography/kevin/_DSC6445-Edit.jpg"
import Cyrena1 from "../Images/juncphotography/Kevin_Cyrena_Yuki (Web Size)/Finals/_DSC5803-Edit.jpg"
import Cyrena2 from "../Images/juncphotography/Kevin_Cyrena_Yuki (Web Size)/Finals/_DSC5645-Edit.jpg"
import Cyrena3 from "../Images/juncphotography/Kevin_Cyrena_Yuki (Web Size)/Finals/_DSC5648-Edit.jpg"
import Cyrena4 from "../Images/juncphotography/Kevin_Cyrena_Yuki (Web Size)/Finals/_DSC5717.jpg"
import Cyrena5 from "../Images/juncphotography/Kevin_Cyrena_Yuki (Web Size)/Finals/_DSC5771.jpg"
import Elaine1 from "../Images/juncphotography/elaine/_DSC4760-Edit.png"
import Elaine2 from "../Images/juncphotography/elaine/_DSC2867.jpg"
import Elaine3 from "../Images/juncphotography/elaine/_DSC2874.jpg"
import Elaine4 from "../Images/juncphotography/elaine/_DSC2961-2-Edit.jpg"
import Shae1 from "../Images/juncphotography/shae/Shae/_DSC5337.jpg"
import Shae2 from "../Images/juncphotography/shae/Shae/_DSC5346.jpg"
import Shae3 from "../Images/juncphotography/shae/Shae/_DSC5362.jpg"
import Shae4 from "../Images/juncphotography/shae/Shae/_DSC5390.jpg"
import Shae5 from "../Images/juncphotography/shae/Shae/_DSC5563-2.jpg"
import Matt1 from "../Images/juncphotography/DSC00621-Edit.jpg"
import Matt2 from "../Images/juncphotography/DSC00627-Edit.jpg"
import Matt3 from "../Images/MattMain.jpeg"
import ParthFam1 from "../Images/juncphotography/parth fam/1171 edit (1).jpg"
import ParthFam2 from "../Images/juncphotography/parth fam/2020Nov26_1166.jpg"
import ParthFam3 from "../Images/juncphotography/parth fam/2020Nov26_1177-Edit (1).jpg"
import ParthFam4 from "../Images/juncphotography/parth fam/2020Nov26_1178-Edit.jpg"
import ParthFam5 from "../Images/juncphotography/parth fam/edit 1180.jpg"


const Gallery = {
    Bert: [
        {
            "original": Bert1,
           
    
        
        },
        {
            "original": Bert2,
            
        },
        {
            "original": Bert3,
            
        },
        {
            "original": Bert4,
           
        },
        {
            "original": Bert5,
            
        },
        {
            "original": Bert6,
        },

    ],

    Louis: [
        {
            "original": Louis1,
            
        },
        {
            "original": Louis2,
           
        },
        {
            "original": Louis3,
            
        },
        {
            "original": Louis4,
            
        },
    ],

    Miguel: [
        {
            "original": Miguel1,
        },
        {
            "original": Miguel2,
        },
        {
            "original": Miguel3,
        },
        {
            "original": Miguel4,
        },
    ],

    Chelsea: [
        {
            "original": Chelsea1,
        },
        {
            "original": Chelsea2,
        },
        {
            "original": Chelsea3,
        },
        {
            "original": Chelsea4,
        },
        {
            "original": Chelsea5,
        },
    ],

    Kevin: [
        {
            "original": Kevin1,
        },
        {
            "original": Kevin2,
        },
        {
            "original": Kevin3,
        },
        {
            "original": Kevin4,
        },
    ],

    Cyrena: [
        {
            "original": Cyrena1,
        },
        {
            "original": Cyrena2,
        },
        {
            "original": Cyrena3,
        },
        {
            "original": Cyrena4,
        },
        {
            "original": Cyrena5,
        },
    ],

    Elaine: [
        {
            "original": Elaine1,
        },
        {
            "original": Elaine2,
        },
        {
            "original": Elaine3,
        },
        {
            "original": Elaine4,
        },
    ],

    Shae: [
        {
            "original": Shae1,
        },
        {
            "original": Shae2,
        },
        {
            "original": Shae3,
        },
        {
            "original": Shae4,
        },
        {
            "original": Shae5,
        },

    ],

    Matt: [
        {
            "original": Matt1,
        },
        {
            "original": Matt2,
        },
        {
            "original": Matt3,
        }
    ],

    ParthFam: [
        {
            "original": ParthFam1,
        },
        {
            "original": ParthFam2,
        },
        {
            "original": ParthFam3,
        },
        {
            "original": ParthFam4,
        },
        {
            "original": ParthFam5,
        },

    ]





}

export default Gallery;