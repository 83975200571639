import React, { useEffect } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import BackToTop from "../Components/BackToTop.jsx";
import "./ServicesAndPricing.css";
// import studio1 from "../Images/image 6.png";
import studio2 from "../Images/parthpricing.jpeg";
// import studio3 from "../Images/image 7.png";
import studio4 from "../Images/Optimized-2020Nov26_1166.jpg";
import pro1 from "../Images/pro2-min.jpeg";
import pro2 from "../Images/pro1-min.jpg";
//import pro3 from "../Images/pro3-min.jpg";
import lifestyle1 from "../Images/image 8.png";
import lifestyle2 from "../Images/image 9.png";
import lifestyle3 from "../Images/image 10.png";
// import lifestyle4 from "../Images/juncphotography/thumbs/_DSC6503-Edit-Edit-min.jpg"
import engage1 from "../Images/engagem4.png";
import engage2 from "../Images/engagem2.jpeg";
import engage3 from "../Images/engagem3.png";
// import engage4 from "../Images/juncphotography/thumbs/6-min.jpeg";
import Nav from '../Components/Nav.jsx';
import DocumentMeta from 'react-document-meta';

export default function ServicesAndPricing() {

  useEffect(() => {
    document.title = 'Capture Your Memories: My Services and Pricing';
  }, []);

  const meta = {
    title: 'Capture Your Memories: My Services and Pricing',
    description: "Discover my comprehensive photography services and pricing options. From weddings and engagements to portraits and events, I'm dedicated to delivering exceptional results that exceed your expectations. Browse my services and pricing page to find the package that best suits your needs and budget.",
    canonical: 'https://juncphotography.com/services',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'Engagement photography, Portrait photography, Lifestyle photography, Love, Relationships, Candid, Intimate, Personal, Memories, Emotions, Poses, Natural Lighting, Creative, Storytelling, Expression, Joy, Elegance, Style, Personality, Fun'
      }
    }
  };

  return (
    <div className="ServicesAndPricing">
      <Nav />
      <DocumentMeta {...meta} />
      <div className="backtotop2">
            <Link to="/services#top">
              <BackToTop />
            </Link>
      </div>
      
        <div className="title">
          <h1>Services & Pricing</h1>
        </div>  
        <div className="mobile-services">
          <Link to="/services#studioo">
            Studio Portraits
          </Link>
          <Link to="/services#lifestylee">
            Lifestyle Portraits
          </Link>
          <Link to="/services#engagementt">
            Engagement
          </Link>      
          </div>
        <div className="studio-container">
          <div className="Studio">
            <h2>Professional Portraits</h2>
            <div className="Studio-packages" id="studioo">
              <div className="Studio-package-card">
                <h3>Quick and Easy</h3>
                <img src={pro1} alt="package 1" />
                <ul>
                  <li>30 mins studio portrait session for 1 quick 
                    professional headshot. Basic touch up/edit.</li>
                  <li>Professional edit available at an additional cost**</li>
                  <li>1 outfit</li>
                </ul>
                <div className="price">
                  <h3>$100</h3>
                </div>  
              </div>
              <div className="Studio-package-card">
                <h3>Basic Package</h3>
                <img src={studio2} alt="package 2" />
                <ul>
                  <li>30 minute studio portrait session with professional backdrops.</li>
                  <li>2 images, one professional edit included, one basic touch up edit included.</li>
                  <li>Additional professional edits available at an additional cost**</li>
                </ul>
                <div className="price">
                  <h3>$200</h3>
                </div>  
              </div>
              <div className="Studio-package-card">
                <h3>Standard Package</h3>
                <img src={pro2} alt="package 3" />
                <ul>
                  <li>1-hour studioportrait session with professional backdrops.</li>
                  <li>5 professionally edited pictures</li>
                  
                </ul>
                <div className="price">
                  <h3>$450</h3>
                </div>  
              </div>
              <div className="Studio-package-card">
                <h3>Premium Package</h3>
                <img src={studio4} alt="package 4" />
                <ul>
                  <li>2-hour studio portrait session with professional backdrops.</li>
                  <li>10 digital images with professional editing  up to 4 outfit changes, and up to 2 background colors.</li>
                </ul>
                <div className="price">
                  <h3>$750</h3>
                </div>  
              </div>
            </div>
          </div>
          {/*<div className="Professional">  REMOVED PROFESSIONAL PORTRAITS
            <h2>Professional Portraits</h2>
            <div className="Professional-packages" id="pro">
              <div className="Studio-package-card">
                <h3>Quick and Easy</h3>
                <div className="pro1">
                  <img src={pro1} alt="package 1"/>
                </div>
                <ul>
                  <li>30 mins portrait session for 1 quick 
                    professional shot. Simple edits.</li>
                    <li>Editorial Style Edits Available At Additional Charge**</li>
                  <li>1 outfit</li>
                </ul>
                <div className="price">
                  <h3>$100</h3>
                </div>  
              </div>
              <div className="Studio-package-card">
                <h3>Basic Package</h3>
                <img src={pro2} alt="package 2" />
                <ul>
                  <li>30 minute portrait session with a one light set 
                  up and natural backdrops.</li>
                  <li>2 images, one editorial style** edit included, one basic edit included.</li>
                  <li>Editorial Style Edits Available At Additional Charge**</li>
                </ul>
                <div className="price">
                  <h3>$200</h3>
                </div>  
              </div>
              <div className="Studio-package-card">
                <h3>Standard Package</h3>
                <img src={pro3} alt="package 3" />
                <ul>
                  <li>1-hour session with a one light 
                  set up and natural backdrops.</li>
                  <li>5 editorial style** edited pictures</li>
                </ul>
                <div className="price">
                  <h3>$450</h3>
                </div>  
              </div>
            </div>
            </div> */}
        </div>  


        

        
          
        



        <div className="lifestyle-container">
          <div className="lifestyle">
            <h2>Lifestyle Portraits</h2>
            
            <div className="lifestyle-packages" id="lifestylee">
              {/*<div className="Studio-package-card">
                <div className="smaller-h3">
                  <h3>Unedited Photography Package</h3>
                </div>
                <img src={lifestyle4} alt="limited package" />
                <ul>
                  <li>One hour</li>
                  <li>15-20 unedited photos</li>
                  <li>Solo edits available at an additional $25-50 per shot</li>
                </ul>
                <div className="price">
                  <h3>$150</h3>
                </div>  
            </div>*/}
              <div className="Studio-package-card">
                <h3>Basic Package</h3>
                <img src={lifestyle1} alt="package 1" />
                <ul>
                  <li>30-minute session</li>
                  <li>20 digital images with basic 
                  retouching </li>
                  <li>Professional Edits Available At Additional Charge**</li>
                </ul>
                <div className="price">
                  <h3>$200</h3>
                </div>  
              </div>
              <div className="Studio-package-card">
                <h3>Standard Package</h3>
                <img src={lifestyle2} alt="package 2" />
                <ul>
                  <li>1-hour session</li>
                  <li>45 digital images with basic 
                  retouching </li>
                  <li>Up to 2 outfit changes</li>
                  <li>1 location of choice*</li>
                </ul>
                <div className="price">
                  <h3>$400</h3>
                </div>  
              </div>
              <div className="Studio-package-card">
                <h3>Premium Package</h3>
                <img src={lifestyle3} alt="package 3" />
                <ul>
                  <li>1.5 hour session</li>
                  <li>65 digital images with basic 
                  retouching </li>
                  <li>Up to 3 outfit changes</li>
                  <li>One location of choice*</li>
                </ul>
                <div className="price">
                  <h3>$600</h3>
                </div>  
              </div>
            </div>
          </div>
         </div> 


         <div className="engagement-container">
          <div className="engagement">
            <h2>Engagement Photography</h2>
            
            <div className="engagement-packages" id="engagementt">
              {/*<div className="engagement-package-card">
                <h3>Unedited Photography Package</h3>
                <img src={engage4} alt="limited package for engagement" />
                <ul>
                  <li>One hour</li>
                  <li>15-20 unedited photos</li>
                  <li>Solo edits available at an additional $25-50 per shot</li>
                </ul>
                <div className="price">
                  <h3>$150</h3>
                </div>  
                  </div>*/}
              <div className="engagement-package-card">
                <h3>Basic Package</h3>
                <div className="engagement-card-image">
                  <div className="engagem3">
                    <img src={engage1} alt="package 1" />
                  </div>
                </div>  
                <ul>
                  <li>1 hour session</li>
                  <li>25 digital images with basic 
                  retouching </li>
                  <li>One location of choice*</li>
                  <li>Professional Edits Available At Additional Charge**</li>
                  
                </ul>
                <div className="price">
                  <h3>$250</h3>
                </div>  
              </div>
              <div className="engagement-package-card">
                <h3>Standard Package</h3>
                <div className="engagement-card-image">
                  <div className="kevin">
                    <img src={engage2} alt="package 2" />
                  </div>
                </div>  
                <ul>
                  <li>1.5 hour session</li>
                  <li>55 digital images with basic 
                  retouching </li>
                  <li>One location of choice*</li>
                  <li>Professional Edits Available At Additional Charge**</li>
                
                </ul>
                <div className="price">
                  <h3>$450</h3>
                </div>  
              </div>
              <div className="engagement-package-card">
                <h3>Premium Package</h3>
                <div className="engagement-card-image">
                  <div className="engagem3">
                    <img src={engage3} alt="package 3" />
                  </div>
                </div>
                <ul>
                  <li>2 hour session</li>
                  <li>75 digital images with basic 
                  retouching </li>
                  <li>One location of choice*</li>
                  <li>Professional Edits Available At Additional Charge**</li>
                </ul>
                <div className="price">
                  <h3>$650</h3>
                </div>  
              </div>
            </div>
          </div>
         </div> 
      
      <div className="get-started">
        <div className="ready">
          <h2>Ready to get started?</h2>
          <form action="/contactme">
            <button type="submit">Book a Session</button>
          </form>  
        </div>
        <div className="asterisk">
          <p>*Location: I serve most areas in southern california in LA, Orange County, Inland Empire, and San Diego. Contact me for more details!</p>
          <p>**Professional editing typically includes techniques such as frequency separation, 
          dodge and burn, skin smoothing, color grading, and background clean-up, which 
          require more time and expertise. Additional Editorials not included in package can be purchased for $25 each.</p>
    
        </div>
      </div>


    </div>
  )
}
