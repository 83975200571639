import React, { useRef, useEffect, useState } from 'react'
import "./HomePage.css";
import { HashLink as Link } from 'react-router-hash-link';
import Splash from "../Images/image 1.png";
import aboutMe from "../Images/aboutme.jpeg";
import BackToTop from "../Components/BackToTop.jsx";
import Nav from '../Components/Nav.jsx';
import Work from "../Components/Work.jsx";
import portraitm from "../Images/image 2.png";
import engagementm from "../Images/juncphotography/thumbs/2-min.jpeg";
import emailjs from '@emailjs/browser';
import DocumentMeta from 'react-document-meta';
import Promotion from "../Images/promotion.png"



export default function HomePage() {


  const[promo, setPromo]= useState(false);

  useEffect(() => {
    document.title = 'Through The Lens: Stunning Shots by Jun Chai';
  }, []);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_zdax107', 'template_y5s20mb', form.current, 'wopzxqh0ARpnWLOMt')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };


  
    const meta = {
      title: 'Through The Lens: Stunning Shots by Jun Chai',
      description: "Los Angeles CA. From professional portraits to capturing life's most precious moments, our team is dedicated to delivering exceptional results that reflect your unique personality and style.",
      canonical: 'https://juncphotography.com/',
      meta: {
        charset: 'utf-8',
        name: {
          keywords: 'Engagement photography, Portrait photography, Lifestyle photography, Professional Portrait, Photography, Photographer, Los Angeles, West Covina, Inland Empire, Orange County, San Diego, Love, Relationships, Candid, Intimate, Personal, Memories, Emotions, Poses, Natural Lighting, Creative, Storytelling, Expression, Joy, Elegance, Style, Personality, Fun, Professional Portrait Photography'
        }
      }
    };

  return (
    
    <div className="HomePage" id="top">
      <DocumentMeta {...meta} />
      

      <Nav />
      {promo && (<div className="promotional">
        <div className="promo-image">
          <img src={Promotion} alt="promotional" />
          <form action="/contactme">
            <button type="submit">Contact me now!</button>
          </form>
          <div className="close-ad" onClick={() => setPromo(false)}>
            <h5>X</h5>
          </div>  
        </div>


      </div>)}



      <div className="splash">
        <div className="splash-text">
          <h3>JUN CHAI</h3>
          <h1>Photography</h1>
          <h3>Southern California</h3>
          <p>Welcome to my photography page, where I specialize in capturing life's most precious moments. Whether it's a memorable photoset for your <b>graduation</b>, the memories of your <b>engagement</b>, a <b>professional photo</b> for your job hunt, or an amazing photo for your social media, I have you covered and will make you shine. Get in touch with me today, let's talk, and don't forget to check out my portfolio!</p>
          <div className="promotion-button">
            <button onClick={() => setPromo(true)}>Limited Time Promotion, click here!</button>
          </div>
        </div>  
        <div className="splash-image">
          <img src={Splash} alt="splash"/>
          
          <div className="splash-background"></div>
          <div className="splash-square"></div>
        </div>
      </div>

      <div className="backtotop">
      <Link to="./#top">
        <BackToTop/>
      </Link>  
      </div>
      <div className="show-work">
        <Work/>
      </div>

      <div className="Portfolio-Mobile">
        <h2>Portfolio</h2>
        <Link to="/Lifestyle-and-Portrait">
        <div className="mobile-card1">
          <h3> LIFESTYLE & PORTRAIT</h3>
          <div className="mobile-bg1"></div>
          
            <img src={portraitm} alt="portrait" />
          
        </div>
        </Link>
        

        <Link to="/Engagement">
        <div className="mobile-card2">
          <h3>ENGAGEMENT</h3>
          <div className="mobile-bg2"></div>
          
            <img src={engagementm} alt="engagement" />
          
          
        </div>
        </Link>
        

      </div>


      <div className="block" id="aboutme"></div>
      <div className="aboutme" >
        <div className="aboutme-image">
          <img src={aboutMe} alt="aboutme"/>
        </div>
        <div className="aboutme-getintouch">
          <div className="aboutme-text">
            <h2>About Me</h2>
            <p>Welcome to my photography website! My name is Jun Chai, and I specialize 
            in portrait and engagement photography. Operating out of the Los Angeles area with over 7 years of experience, I 
            have developed a unique approach that combines natural light and flash 
            photography to create beautiful, natural-looking images. I believe that the 
            combination of natural and artificial light allows me to capture the perfect 
            shot, while showcasing the beauty and personality of my subjects.
            I strive to create a comfortable, relaxed environment for my clients during 
            our photoshoots. I understand that being in front of the camera can be 
            nerve-wracking, and I aim to create a fun and enjoyable experience for 
            everyone involved. My ultimate goal is to capture your unique story and 
            create images that you'll cherish for a lifetime. Whether you're looking for a 
            romantic engagement session or a professional portrait, I'm here to make 
            your vision a reality. Thank you for considering me as your photographer, 
            and I look forward to working with you soon.</p>
          </div>
          <div className="getintouch">
            <h2>Get in Touch</h2>
            <form ref={form} onSubmit={sendEmail}>
              <div className="name-email">
                <div className="textfield">
                  <label for="name">Full Name</label>
                  <input type="text" id="name" name="from_name" />
                </div>
                <div className="textfield">
                  <label for="email">Email Address</label>
                  <input type="text" id="email" name="from_email" />
                </div>
              </div>
              <div className="selection">
                <label for="interest">I am interested in...</label>
                <select id="interest" name="session_type">
                  <option value="Portrait Session">Portrait Session</option>
                  <option value="Lifestyle Session">Lifestyle Session</option>
                  <option value="Engagement Session">Engagement Session</option>
                </select>
              </div>
              <div className="message">
                <label for="message">Message</label>
                <textarea className="message-input" name="message"></textarea>
              </div>
              <div className="submit">
                <button onClick={() => {alert("Email sent! You'll hear back from me soon!")}} type="submit">Send Message</button>
              </div>
            </form>  
          </div>
        </div>

      </div>
    </div>
  )
}
