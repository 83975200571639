import React, { useState, useEffect, useRef } from 'react'
import LifestyleSplash from '../Images/lifestylesplash.png';
import "./Lifestyle.css";
import Nav from '../Components/NavLifestyle.jsx';
import Gallery from '../Data/Gallery';
import ImageGallery from 'react-image-gallery';
import Bert from '../Images/BertMain-min.jpeg';
import Louis from "../Images/juncphotography/thumbs/_DSC6503-Edit-Edit-min.jpg";
import Miguel from "../Images/juncphotography/thumbs/_DSC5124-Edit-Edit-min.jpg"
import Chelsea from "../Images/juncphotography/thumbs/_DSC4305-Edit-min.jpg";
import Cyrena from "../Images/juncphotography/thumbs/_DSC5803-Edit-min.jpg";
import Elaine from "../Images/juncphotography/thumbs/_DSC4760-Edit-min.png";
import Matt from "../Images/juncphotography/thumbs/DSC00621-Edit-min.jpg";
import ParthFamily from "../Images/juncphotography/parth fam/edit 1180.jpg";
import emailjs from '@emailjs/browser';
import DocumentMeta from 'react-document-meta';
import useScript from "../Components/useScript";

export default function Lifestyle() {

    useScript('https://www.google.com/recaptcha/api.js');

    const [message, setMessage] = useState("");
    const [message2, setMessage2] = useState("");
    const [message3, setMessage3] = useState("");
    

    const handleChange = event => {
        setMessage(event.target.value);
      };
    const handleChange2 = event => {
        setMessage2(event.target.value);
      };
    const handleChange3 = event => {
        setMessage3(event.target.value);
      };
    useEffect(() => {
        document.title = 'Capturing Character: Professional Portrait Photography';
      }, []);
    
    const[pictures, setPictures] = useState([]);
    const[closeGallery, setCloseGallery] = useState();
    useEffect(() => {
            setPictures([]);
        }, []);
    
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_zdax107', 'template_y5s20mb', form.current, 'wopzxqh0ARpnWLOMt')
      .then((result) => {
          console.log(result.text);
          alert("Email sent! You'll hear back from me soon!");
          document.location.reload();
      }, (error) => {
          console.log(error.text);
          alert("NOT SENT! Make sure all fields are valid and the reCAPTCHA challenge is completed, and try again.")
          
      });
  };


  const meta = {
    title: 'Capturing Character: Professional Portrait Photography',
    description: "Capture your unique personality and style with my professional portrait and lifestyle photography services. My efforts deliver exceptional results through creative and personalized photography, from professional headshots to candid everyday moments. Browse my portfolio and contact me to schedule your session today.",
    canonical: 'https://juncphotography.com/Lifestyle-and-Portrait',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'Engagement photography, Portrait photography, Lifestyle photography, Professional Portrait, Photography, Photographer, Love, Relationships, Candid, Intimate, Personal, Memories, Emotions, Poses, Natural Lighting, Creative, Storytelling, Expression, Joy, Elegance, Style, Personality, Fun'
      }
    }
  };

  return (
    <div className="main-page">
        <DocumentMeta {...meta} />
        <Nav />
        <div className="lifestyle-portrait">

            <img src={LifestyleSplash} alt="Lifestyle Splash" />
            <div className="lifestyle-portrait-text">
                <h3>JUN CHAI</h3>
                <h1>Lifestyle & Portrait</h1>
                <p>I aim to capture the essence of my clients' unique personalities and lifestyles 
through my lens. From candid moments to carefully curated poses, my goal is to 
create timeless and authentic images that will be treasured for years to come. 
Whether you're looking for a family portrait, headshot, or lifestyle shoot, I am here 
to help you create lasting memories that showcase your individuality and style.</p>
                <div className="buttons">
                    <form action="/contactme">
                        <button type="submit">Contact Me</button>
                    </form>
                    <div className="button2">
                        <form action='/services'>
                            <button type="submit">View Pricing</button>
                        </form>
                    </div>   
                </div>
            </div>
        </div>
        <div className="greenish-square"></div>


        <div className="gallery-background"> </div>
        <div className="gallery">
            <div className="gallery-items">
                <div onClick={() => {
                    setPictures([]);
                    setPictures(Gallery.Bert);
                    setCloseGallery((<div className="clicker" onClick={() => {
                        setPictures([]);
                        setCloseGallery();
                    }}>
            
                       <p>Close Gallery X</p>
            
                    </div>))
                }} className="gallery-card">
                    <div className="photo-container-gallery">
                        <img src={Bert} alt="Bert" />
                    </div>    
                    <p>Bert's Portrait Shoot</p>
                </div>



                <div onClick={() => {
                    setPictures([]);
                    setPictures(Gallery.Louis);
                    setCloseGallery((<div className="clicker" onClick={() => {
                        setPictures([]);
                        setCloseGallery();
                    }}>
            
                        <p>Close Gallery X</p>
            
                    </div>))
                }}  className="gallery-card">
                    <div className="photo-container-gallery">
                        <img align="bottom" src={Louis} alt="Louis" />
                    </div>
                    <p>Louis' Lifestyle Shoot</p>
                </div>



                <div onClick={() => {
                    setPictures([]);
                    setPictures(Gallery.Miguel);
                    setCloseGallery((<div className="clicker" onClick={() => {
                        setPictures([]);
                        setCloseGallery();
                    }}>
            
                       <p>Close Gallery X</p>
            
                    </div>))
                }}  className="gallery-card">
                    <div className="photo-container-gallery">
                        <img src={Miguel} alt="Miguel" />
                    </div>
                    <p>Miguel's Lifestyle Shoot</p>
                </div>



                <div onClick={() => {
                    setPictures([]);
                    setPictures(Gallery.Chelsea);
                    setCloseGallery((<div className="clicker" onClick={() => {
                        setPictures([]);
                        setCloseGallery();
                    }}>
            
                       <p>Close Gallery X</p>
            
                    </div>))
                }}   className="gallery-card">
                    <div className="photo-container-gallery">
                        <img src={Chelsea} alt="Chelsea" />
                    </div>    
                    <p>Chelsea's Lifestyle Shoot</p>
                </div>



            </div>
            <div className="gallery-items">
                <div onClick={() => {
                    setPictures([]);
                    setPictures(Gallery.Matt);
                    setCloseGallery((<div className="clicker" onClick={() => {
                        setPictures([]);
                        setCloseGallery();
                    }}>
            
                       <p>Close Gallery X</p>
            
                    </div>))
                }}   className="gallery-card">
                    <div className="photo-container-gallery">
                        <img src={Matt} alt="Album" />
                    </div>
                    <p>Matt's Portraits</p>
                </div>


                <div onClick={() => {
                    setPictures([]);
                    setPictures(Gallery.Cyrena);
                    setCloseGallery((<div className="clicker" onClick={() => {
                        setPictures([]);
                        setCloseGallery();
                    }}>
            
                       <p>Close Gallery X</p>
            
                    </div>))
                }}   className="gallery-card">
                    <div className="photo-container-gallery">
                        <img src={Cyrena} alt="Album" />
                    </div>
                    <p>Kevin, Cyrena & Yuki</p>
                </div>


                <div onClick={() => {
                    setPictures([]);
                    setPictures(Gallery.Elaine);
                    setCloseGallery((<div className="clicker" onClick={() => {
                        setPictures([]);
                        setCloseGallery();
                    }}>
            
                       <p>Close Gallery X</p>
            
                    </div>))
                }}   className="gallery-card">
                    <div className="photo-container-gallery">
                        <img src={Elaine} alt="Album" />
                    </div>
                    <p>Elaine's Lifestyle & Portrait</p>
                </div>

                <div onClick={() => {
                    setPictures([]);
                    setPictures(Gallery.ParthFam);
                    setCloseGallery((<div className="clicker" onClick={() => {
                        setPictures([]);
                        setCloseGallery();
                    }}>
            
                       <p>Close Gallery X</p>
            
                    </div>))
                }}   className="gallery-card">
                    <div className="photo-container-gallery">
                        <img src={ParthFamily} alt="Album" />
                    </div>
                    <p>Parth's Family Studio Portraits</p>
                </div>
            </div>
        </div>    

        <div className="getintouchgallery1">
        <div className="getintouch">
            <h2>Get in Touch</h2>
            <form ref={form} onSubmit={sendEmail}>
              <div className="name-email">
                <div className="textfield">
                  <label for="name">Full Name</label>
                  <input type="text" id="name" name="from_name" value={message} onChange={handleChange} required />
                </div>
                <div className="textfield">
                  <label for="email">Email Address</label>
                  <input type="email" id="email" name="from_email" placeholder="user@example.com" value={message2} onChange={handleChange2} required />
                </div>
              </div>
              <div className="selection">
                <label for="interest">I am interested in...</label>
                <select id="interest" name="session_type">
                  <option value="Portrait Session">Portrait Session</option>
                  <option value="Lifestyle Session">Lifestyle Session</option>
                  <option value="Engagement Session">Engagement Session</option>
                </select>
              </div>
              <div className="message">
                <label for="message">Message</label>
                <textarea className="message-input" name="message" value={message3} onChange={handleChange3}></textarea>
              </div>
              <div className="submit">
                <div class="g-recaptcha" data-sitekey="6LfX2_YlAAAAAIccRCQrHvQMFb9eaazs_9VJp3N5"></div>
                <br />
                <button  disabled={message==="" || message2==="" || message2.length<9 || message3==="" || message3.length<20} type="submit">Send Message</button>
              </div>
            </form>  
          </div>
          </div> 


          <div className="gallery-viewer"> 
                {closeGallery}
                <ImageGallery items={pictures} showThumbnails={false} showFullscreenButton={false} showPlayButton={false} showBullets={true} />
          </div>
        
        
      
    </div>
  )
}
